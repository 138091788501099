function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded Unicode,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      }
    )
  );
}

export const callService = async (type, data) => {
  var resData = { status: false, info: null };
  try {
    data = { ...data, type };
    //hmacSHA512(JSON.stringify(data), process.env.REACT_APP_API_PRIVATE);

    //console.log(data);
    //let encoded = btoa(JSON.stringify(data));
    let encoded = b64EncodeUnicode(JSON.stringify(data));
    //console.log(encoded);
    const response = await fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      body: encoded, //JSON.stringify(data),
      //mode: "no-cors",
      headers: { "Content-type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.json())
      .catch((error) => {
        console.log(error);
      });
    console.log(response);
    resData = { ...response };
  } catch (error) {
    console.log(error);
    resData.info = error;
  }
  return resData;
};
