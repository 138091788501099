import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { callService } from "../service/service";
import LinearProgress from "@mui/material/LinearProgress";
import "../App.css";

export default function WordsLessons({ changeStep, course }) {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const checkWords = async () => {
      let date = new Date();
      let resp = await callService("LESSONS", {
        token: localStorage.getItem("token"),
        course: course.id,
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
      });
      console.log(resp);
      if (resp && resp.status) {
        setLessons(resp.lessons);
      } else {
        window.location.reload(false);
      }
    };
    checkWords();
  }, [course]);

  const goBack = () => {
    changeStep("menu");
  };

  const changeActive = async (ord, id) => {
    let ls = [...lessons];
    let newValue = ls[ord]["active"] ? 0 : 1;
    ls[ord].active = newValue;
    setLessons(ls);
    let cc = await callService("UPDATEWORD", {
      token: localStorage.getItem("token"),
      id,
      value: newValue,
    });
    console.log(cc);
  };

  return (
    <div className="mainBox">
      <div className="backBox">
        <Button
          variant="contained"
          style={{ backgroundColor: "gray" }}
          onClick={goBack}
        >
          Volver
        </Button>
      </div>
      <div className="subBox">
        {lessons.length > 0 ? (
          lessons.map((o, index) => (
            <Button key={index} variant="contained" style={{ margin: 10 }}>
              {"active" in o && (
                <input
                  type="checkbox"
                  checked={o.active}
                  style={{ position: "absolute", left: 3 }}
                  onChange={() => {}}
                  onClick={() => {
                    changeActive(index, o.id);
                  }}
                />
              )}
              <span
                onClick={() => {
                  changeStep("words", o.id);
                }}
              >
                {o.name}
              </span>
            </Button>
          ))
        ) : (
          <div>
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          </div>
        )}
      </div>
    </div>
  );
}
