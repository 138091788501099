import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { callService } from "../service/service";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../App.css";
import {
  listenText,
  recordVoice,
  stopPlaying,
  stopRecording,
} from "../js/audio";
import LinearProgress from "@mui/material/LinearProgress";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import CancelIcon from "@mui/icons-material/Cancel";
import RecordIcon from "../images/recording.gif";
import CheckIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/Circle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";

import { convertSesp, convertYale } from "../js/convert";
import * as cn from "chinese-numbering";

var tries = [false, false, false, false, false, false];
var contIndex = 0;
function TitleText({ txt }) {
  return (
    <div style={{ textAlign: "center", fontSize: 30 }}>
      <b>{txt}</b>
    </div>
  );
}

var timing = false;

export default function Words({ changeStep, value, course }) {
  const [words, setWords] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [recording, setRecording] = useState(false);
  const [score, setScore] = useState(0);

  const getPhonetic = (data, dictionary) => {
    let box = [];
    data.forEach((element) => {
      console.log("nn ", element);
      if (element !== "" && dictionary[element]) {
        let theWord = dictionary[element]["cant"].split(" ");

        theWord.map((o, index) => {
          console.log(o, index);
          let parts = o.split("|")[0];
          console.log(parts);
          parts = [parts.slice(0, -1), parts.substr(parts.length - 1)];
          console.log(parts);

          if (parts[0] in course.sounds) {
            switch (course.phonetic) {
              case "sesp":
                theWord[index] = convertSesp(
                  course.sounds[parts[0]][course.phonetic],
                  parts[1],
                  contIndex
                );
                break;
              case "jyut":
                theWord[index] = (
                  <>
                    <span key={contIndex}>
                      {course.sounds[parts[0]][course.phonetic] + parts[1]}
                    </span>{" "}
                  </>
                );

                break;
              default:
                theWord[index] = convertYale(
                  course.sounds[parts[0]][course.phonetic],
                  parts[1],
                  contIndex
                );
                break;
            }
            contIndex++;
          }
          return true;
        });

        console.log(theWord);
        box.push(...theWord);
      }
    });
    return box;
  };

  const processDict = (words, isObject = false) => {
    let phonetic = {};
    if (isObject) {
      let tempWords = [];
      Object.keys(words).map((o) => tempWords.push([words[o]]));
      words = tempWords;
    }
    words.forEach((element) => {
      ["", "_c"].forEach((o) => {
        if (element[0]["tra" + o]) {
          phonetic[element[0]["tra" + o]] = {
            tra: element[0]["tra" + o],
            simp: element[0]["simp" + o]
              ? element[0]["simp" + o]
              : element[0]["tra" + o],
            cant: element[0]["cant" + o],
            esp: element[0]["esp"],
          };
        }
      });
    });
    return phonetic;
  };

  const handleOpen = async (o) => {
    setPlaying(false);
    setRecording(false);
    setOpen(true);
    setData(false);

    let resp = await callService("WORDINFO", {
      token: localStorage.getItem("token"),
      id: o.id,
      word: o.word,
    });

    console.log(resp);
    if (resp && resp.status) {
      let dictionary = processDict(resp.wordsInfo);
      console.log(dictionary);
      contIndex = 0;
      let phonetic = getPhonetic(o.word.split("-"), dictionary);
      let realPhonetic = [];
      o.word.split("-").forEach((h) => {
        let sndPart = dictionary[h].cant.split(" ");
        sndPart.map((v, index) => (sndPart[index] = v.split("|")[0]));
        realPhonetic.push(...sndPart);
      });
      console.log("----");
      console.log(realPhonetic);

      let phraseParts = o.word.split("-");
      if (course.chart === 1) {
        phraseParts.map(
          (o, index) => (phraseParts[index] = dictionary[o]["simp"])
        );
      }

      setData({
        id: o.id,
        title: o.translate,
        chinese: phraseParts.join(""),
        trad: o.word.split("-").join(""),
        success: resp.success.length > 0 ? resp.success[0]["success"] : 0,
        phonetic,
        dictionary,
        realPhonetic,
      });
    } /*else {
      window.location.reload(false);
    }*/
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log("enter word");
    console.log(course);
    const checkWords = async () => {
      let resp = await callService("WORDS", {
        token: localStorage.getItem("token"),
        lesson: value,
      });
      console.log(resp);
      if (resp && resp.status) {
        setWords(resp.words);
      } /* else {
        window.location.reload(false);
      }*/
    };
    checkWords();
  }, [value]);

  const goBack = () => {
    changeStep("wordsLessons");
  };

  const sendSuccess = () => {
    setRecording("finished");
  };

  const processText = (txt) => {
    clearTimeout(timing);
    setRecording("process");
    sendingData(txt);
  };

  const sendingData = async (txt) => {
    let sendData = { success: 0, attemps: 1, word: data.id };
    let phoneticRes = null;
    if (!isNaN(txt.split(" ")[0])) {
      txt = cn.numberToChinese(txt.split(" ")[0], "traditional");
    }
    console.log("compare", txt, data.trad);

    if (txt === data.trad) {
      sendData.success = 1;
      setScore(100);
    } else {
      //setScore(0);
      let process = await callService("PROCESSWORDS", {
        token: localStorage.getItem("token"),
        txt,
      });
      if (process && process.status) {
        console.log(process.dict);
        let dictResult = processDict(process.dict, true);
        console.log(dictResult);
        let newPhonetic = [];
        process.words.forEach((o) => {
          let sndPart = dictResult[o].cant.split(" ");
          sndPart.map((v, index) => (sndPart[index] = v.split("|")[0]));
          newPhonetic.push(...sndPart);
        });
        let errors = 0;

        data.realPhonetic.map((h, index) => {
          if (newPhonetic[index]) {
            if (h != newPhonetic[index]) {
              let wordSnd = [h.slice(0, -1), h.substr(h.length - 1)];
              let userSnd = [
                newPhonetic[index].slice(0, -1),
                newPhonetic[index].substr(newPhonetic[index].length - 1),
              ];
              if (wordSnd[0] != userSnd[0]) {
                errors += 0.75;
              }
              if (wordSnd[1] != userSnd[1]) {
                errors += 0.25;
              }
            }
          } else {
            errors++;
          }
          return true;
        });

        //console.log(data.realPhonetic.length, errors);
        //console.log(100 - (errors * 100) / data.realPhonetic.length);

        let finalScore = Math.round(
          100 - (errors * 100) / data.realPhonetic.length
        );

        setScore(finalScore);
        if (finalScore >= 80) {
          sendData.success = 1;
        }

        phoneticRes = getPhonetic(process.words, dictResult);
      } /* else {
        window.location.reload(false);
      }*/
    }

    console.log(sendData);

    let resp = await callService("RECORDPRACTICE", {
      ...sendData,
      token: localStorage.getItem("token"),
    });
    console.log(resp);
    if (resp && resp.success) {
      setData({ ...data, success: resp.success, phoneticRes });
    } /*else {
      window.location.reload(false);
    }*/

    sendSuccess();
  };

  const stopRecord = () => {
    //setRecording("searching");
  };

  const changePlaying = () => {
    setPlaying(false);
  };

  const getIconScore = () => {
    if (score === 100) {
      return (
        <p style={{ textAlign: "center" }}>
          <TitleText txt={"¡¡¡Perfecto!!!"} />
          <TitleText txt={score + "%"} />
          <CheckIcon
            className="IconButton"
            style={{
              fontSize: 50,
              backgroundColor: "green",
              marginTop: 10,
            }}
            onClick={() => {
              setRecording(false);
            }}
          />
        </p>
      );
    } else if (score >= 80 && score < 100) {
      return (
        <p style={{ textAlign: "center" }}>
          <TitleText txt={"Excelente"} />
          <TitleText txt={score + "%"} />
          <div style={{ color: "red", fontSize: 25, lineHeight: 2.5 }}>
            {data.phoneticRes}
          </div>
          <div style={{ color: "green", fontSize: 25, lineHeight: 2.5 }}>
            {data.phonetic}
          </div>
          <CheckIcon
            className="IconButton"
            style={{
              fontSize: 50,
              backgroundColor: "green",
              marginTop: 10,
            }}
            onClick={() => {
              setRecording(false);
            }}
          />
        </p>
      );
    } else if (score >= 50 && score < 80) {
      return (
        <p style={{ textAlign: "center" }}>
          <TitleText txt={"Vas mejorando pero sigue practicando"} />
          <TitleText txt={score + "%"} />
          <div style={{ color: "red", fontSize: 25, lineHeight: 2.5 }}>
            {data.phoneticRes}
          </div>
          <div style={{ color: "green", fontSize: 25, lineHeight: 2.5 }}>
            {data.phonetic}
          </div>
          <DoNotDisturbOnIcon
            className="IconButton"
            style={{
              fontSize: 50,
              backgroundColor: "orange",
              marginTop: 10,
            }}
            onClick={() => {
              setRecording(false);
            }}
          />
        </p>
      );
    } else if (score < 50) {
      return (
        <p style={{ textAlign: "center" }}>
          <TitleText txt={"Vuelve a intentarlo"} />
          <TitleText txt={score + "%"} />
          <div style={{ color: "red", fontSize: 25, lineHeight: 2.5 }}>
            {data.phoneticRes}
          </div>
          <div style={{ color: "green", fontSize: 25, lineHeight: 2.5 }}>
            {data.phonetic}
          </div>
          <CancelIcon
            className="IconButton"
            style={{
              fontSize: 50,
              backgroundColor: "red",
              marginTop: 10,
            }}
            onClick={() => {
              setRecording(false);
            }}
          />
        </p>
      );
    } else {
      return (
        <p style={{ textAlign: "center" }}>
          <CancelIcon
            className="IconButton"
            style={{
              fontSize: 50,
              backgroundColor: "red",
            }}
            onClick={() => {
              setRecording(false);
            }}
          />
          <TitleText txt="Intente de nuevo" />
        </p>
      );
    }
  };

  return (
    <div className="mainBox">
      <div className="backBox">
        <Button
          variant="contained"
          onClick={goBack}
          style={{ backgroundColor: "gray" }}
        >
          Volver
        </Button>
      </div>

      <div className="subBox">
        {words.length > 0 ? (
          words.map((o, index) => (
            <Button
              key={index}
              variant="contained"
              style={{ margin: 10 }}
              onClick={() => {
                handleOpen(o);
              }}
            >
              {o.translate}
            </Button>
          ))
        ) : (
          <div>
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={null}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{ ...style, minWidth: 250, borderRadius: 5 }}
          onClick={() => {
            if (recording === "finished") {
              setRecording(false);
            }
          }}
        >
          {recording === "searching" ? (
            <>
              <TitleText txt="Buscando" />
              <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
            </>
          ) : recording === "finished" ? (
            getIconScore()
          ) : recording === "process" ? (
            <p style={{ textAlign: "center" }}>Procesando</p>
          ) : recording ? (
            <p style={{ textAlign: "center" }}>
              <img style={{ height: 140 }} src={RecordIcon} alt="record" />
            </p>
          ) : data ? (
            <>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  lineHeight: 2,
                }}
              >
                {data.phonetic}
              </p>
              <p style={{ textAlign: "center", fontSize: 30 }}>
                {data.chinese}
              </p>
              <h2 style={{ textAlign: "center" }}>{data.title}</h2>
              <div
                className="mainBox"
                style={{ flexDirection: "row", paddingBottom: 15 }}
              >
                {tries.map((o, index) =>
                  index < data.success ? (
                    <CheckIcon
                      key={index}
                      className="IconButton2"
                      style={{
                        fontSize: 30,
                        backgroundColor: "green",
                      }}
                    />
                  ) : (
                    <CircleIcon
                      key={index}
                      className="IconButton2"
                      style={{
                        fontSize: 30,
                        backgroundColor: "gray",
                      }}
                    />
                  )
                )}
              </div>
              <div className="mainBox" style={{ flexDirection: "row" }}>
                {!playing ? (
                  <PlayArrowIcon
                    className="IconButton"
                    style={{
                      fontSize: 50,
                    }}
                    onClick={() => {
                      setPlaying(true);
                      listenText(data.chinese, changePlaying);
                    }}
                  />
                ) : (
                  <StopIcon
                    className="IconButton"
                    style={{
                      fontSize: 50,
                      backgroundColor: "red",
                    }}
                    onClick={() => {
                      setPlaying(false);
                      stopPlaying();
                    }}
                  />
                )}

                <MicIcon
                  className="IconButton"
                  style={{
                    fontSize: 50,
                  }}
                  onClick={() => {
                    timing = setTimeout(() => {
                      setRecording(false);
                      stopRecording();
                    }, 7000);
                    setRecording(true);
                    recordVoice(processText, stopRecord);
                  }}
                />
              </div>
            </>
          ) : (
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          )}

          {!recording && data && (
            <CancelIcon
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                fontSize: 40,
                color: "red",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 250,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
