import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { callService } from "../service/service";
import LinearProgress from "@mui/material/LinearProgress";
import "../App.css";

export default function StudentsList({ changeStep, course }) {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const checkWords = async () => {
      let date = new Date();
      let resp = await callService("STUDENTSLIST", {
        token: localStorage.getItem("token"),
        course: course.id,
      });
      console.log(resp);
      if (resp && resp.status) {
        resp.students.map((v) => (v["total"] = resp.total[0].total * 6));
        setStudents(resp.students);
      } else {
        window.location.reload(false);
      }
    };
    checkWords();
  }, [course]);

  const goBack = () => {
    changeStep("menu");
  };

  return (
    <div className="mainBox">
      <div className="backBox">
        <Button
          variant="contained"
          onClick={goBack}
          style={{ backgroundColor: "gray" }}
        >
          Volver
        </Button>
      </div>
      <div className="subBox">
        {students.length > 0 ? (
          students.map((o, index) => (
            <Button
              key={index}
              variant="contained"
              style={{ margin: 10, backgroundColor: "#C26400" }}
              onClick={() => {
                changeStep("studentInfo", o.id, o.NOMBRE.toUpperCase());
              }}
            >
              <div>
                <b style={{ fontSize: 15 }}>{o.NOMBRE}</b>
                {o.SUCCESS > 0 && <br />}
                {o.SUCCESS > 0 && o.SUCCESS + "/" + o.total}
                <br />
                Intentos: {o.ATTEMPTS ? o.ATTEMPTS : 0}
              </div>
            </Button>
          ))
        ) : (
          <div>
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          </div>
        )}
      </div>
    </div>
  );
}
