import "./App.css";

// react
import { useState, useEffect } from "react";
import { callService } from "./service/service";

// components
import Login from "./components/Login";
import Lessons from "./components/Lessons";
import Menu from "./components/Menu";
import LessonInfo from "./components/LessonInfo";
import WordsLessons from "./components/WordsLessons";
import Words from "./components/Words";
import Phrases from "./components/Phrases";
import StudentsList from "./components/StudentsList";
import StudentInfo from "./components/StudentInfo";
import WordInfo from "./components/WordInfo";

document.title = "Curso";

function App() {
  const [step, setStep] = useState(false);
  const [course, setCourse] = useState(false);

  useEffect(() => {
    const checkToken = async () => {
      if (localStorage.getItem("token")) {
        console.log(localStorage.getItem("token"));
        let resp = await callService("TOKEN", {
          token: localStorage.getItem("token"),
        });
        console.log(resp);
        if (resp && resp.status) {
          let sounds = {};
          if (resp.sounds) {
            resp.sounds.forEach((element) => {
              sounds[element.yale] = { sesp: element.sesp, jyut: element.jyut };
            });
          }
          console.log(sounds);
          if (resp.courses.length === 1) {
            loadCourse({
              ...resp.courses[0],
              chart: resp.chart,
              phonetic: resp.phonetic,
              sounds,
            });
          }
        }
      }
    };
    checkToken();
    //console.log(localStorage.getItem("logged"));
  }, []);

  const changeStep = (id, value = "", student = "", name = "") => {
    let vv = { id, value, student, name };
    console.log("vv");
    console.log(vv);
    setStep(vv);
  };

  const loadCourse = (data) => {
    setCourse({ ...data });
    setStep({ id: "menu" });
  };

  const logout = () => {
    localStorage.clear();
    setStep(false);
  };

  const theSteps = () => {
    switch (step.id) {
      case "menu":
        return <Menu changeStep={changeStep} logout={logout} course={course} />;
      case "lessons":
        return <Lessons changeStep={changeStep} course={course} />;
      case "lessonInfo":
        return (
          <LessonInfo
            changeStep={changeStep}
            value={step.value}
            course={course}
          />
        );
      case "wordsLessons":
        return <WordsLessons changeStep={changeStep} course={course} />;
      case "words":
        return (
          <Words changeStep={changeStep} value={step.value} course={course} />
        );
      case "phrases":
        return <Phrases changeStep={changeStep} course={course} />;
      case "students":
        return <StudentsList changeStep={changeStep} course={course} />;
      case "studentInfo":
        return (
          <StudentInfo
            changeStep={changeStep}
            value={step.value}
            course={course}
            name={step.student}
          />
        );
      case "wordInfo":
        return (
          <WordInfo
            changeStep={changeStep}
            lesson={step.value}
            student={step.student}
            name={step.name}
          />
        );
      default:
        return <Login logout={logout} loadCourse={loadCourse} />;
    }
  };

  return <div className="App">{theSteps()}</div>;
}

export default App;
