import React from "react";
import Button from "@mui/material/Button";
import "../App.css";

export default function Menu({ changeStep, logout, course }) {
  const buttons = [
    {
      label: "Lecciones",
      func: () => {
        changeStep("lessons");
      },
    },
    {
      label: "Práctica",
      func: () => {
        changeStep("wordsLessons");
      },
    },
    {
      label: "Estudiantes",
      func: () => {
        changeStep("students");
      },
    },
  ];
  return (
    <div className="mainBox">
      <div className="subBox" style={{ marginTop: 30 }}>
        {buttons.map(
          (o, index) =>
            (course.teacher || index < 2) && (
              <Button
                key={index}
                variant="contained"
                style={{
                  margin: 10,
                  backgroundColor:
                    o.label === "Lecciones"
                      ? "green"
                      : o.label === "Estudiantes"
                      ? "#C26400"
                      : "",
                }}
                onClick={o.func}
              >
                {o.label}
              </Button>
            )
        )}
      </div>
      <Button
        variant="contained"
        style={{ margin: 0, backgroundColor: "red" }}
        onClick={logout}
      >
        Salir
      </Button>
    </div>
  );
}
