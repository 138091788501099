import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import "../App.css";

export default function LessonInfo({ changeStep, value, course }) {
  const [data, setData] = useState([1, "sesp", 0]);

  useEffect(() => {
    console.log(value, course.phonetic, course.chart);
    setData([value, course.phonetic, course.chart]);
  }, [value]);

  const goBack = () => {
    changeStep("lessons");
  };

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          direction: "row",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Button
          variant="contained"
          onClick={goBack}
          style={{ backgroundColor: "gray" }}
        >
          Volver
        </Button>
      </div>

      <iframe
        style={{
          position: "relative",
          width: "100%",
          flex: 1,
        }}
        src={`https://cantonesonline.com/lessons.php?code=${data[0]}&phonetic=${data[1]}&character=${data[2]}`}
      />
    </div>
  );
}
