import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { callService } from "../service/service";
import { course } from "../variables/main";

export default function Login({ loadCourse }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const checkInfo = async () => {
    //console.log(email, password);
    let resp = await callService("LOGIN", { email: email, password: password });
    console.log(resp);
    if (resp.status) {
      let sounds = {};
      if (resp.sounds) {
        resp.sounds.forEach((element) => {
          sounds[element.yale] = element;
        });
      }

      if (resp.courses.length === 1) {
        localStorage.setItem("token", resp.token);
        loadCourse({
          ...resp.courses[0],
          chart: resp.chart,
          phonetic: resp.phonetic,
          sounds,
        });
        //logout();
      }
    } else {
      setMessage("Credenciales incorrectas");
    }
    console.log(resp);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 250,
          margin: 30,
          borderRadius: 10,
          padding: 10,
          border: "solid 3px black",
        }}
      >
        <div>
          <TextField
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            label="Email"
            variant="outlined"
            style={{ padding: 10 }}
          />
        </div>
        <div>
          <TextField
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            label="Password"
            variant="outlined"
            type="password"
            style={{ padding: 10 }}
          />
        </div>
        <Button variant="contained" onClick={checkInfo}>
          Entrar
        </Button>
        <span style={{ color: "red", padding: 5 }}>{message}</span>
      </div>
    </div>
  );
}
