import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { callService } from "../service/service";
import LinearProgress from "@mui/material/LinearProgress";
import "../App.css";

export default function WordInfo({ changeStep, lesson, student, name }) {
  const [words, setWords] = useState([]);

  useEffect(() => {
    const checkWords = async () => {
      let resp = await callService("WORDS", {
        token: localStorage.getItem("token"),
        lesson,
        student,
      });
      console.log(resp);
      if (resp && resp.status) {
        setWords(resp.words);
      } /*else {
        window.location.reload(false);
      }*/
    };
    checkWords();
  }, [lesson]);

  const goBack = () => {
    changeStep("studentInfo", student, name);
  };

  return (
    <div className="mainBox">
      <div className="backBox">
        <div style={{ paddingBottom: 10 }}>
          <b>{name}</b>
        </div>
        <Button
          variant="contained"
          onClick={goBack}
          style={{ backgroundColor: "gray" }}
        >
          Volver
        </Button>
      </div>

      <div className="subBox" style={{ marginTop: 90 }}>
        {words.length > 0 ? (
          words.map((o, index) => (
            <Button
              key={index}
              variant="contained"
              style={{ margin: 10, backgroundColor: "#C26400" }}
            >
              {o.translate}
              <br />
              {o.word.split("-").join("")}
              {o.success > 0 && <br />}
              {o.success > 0 && o.success + "/" + o.total}
              <br />
              Intentos: {o.attempts ? o.attempts : 0}
            </Button>
          ))
        ) : (
          <div>
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          </div>
        )}
      </div>
    </div>
  );
}
