const finals = {
  a: "á",
  e: "é",
  i: "í",
  o: "ó",
  u: "ú",
  ü: "ǘ",
  ng: "ńg",
  n: "ń",
  m: "ḿ",
  k: "k´",
};

const vocals = ["a", "e", "i", "o", "u", "ⱥ", "ɇ", "ø", "ü"];

const yaleVocals = {
  1: { a: "ā", e: "ē", i: "ī", o: "ō", u: "ū" },
  2: { a: "á", e: "é", i: "í", o: "ó", u: "ú" },
  4: { a: "à", e: "è", i: "ì", o: "ò", u: "ù" },
};

export const convertSesp = (base, tone, index) => {
  let rev = -1;
  if (["2", "5", "4"].includes(tone)) {
    if (base.length === 1) {
      base = base + base;
    }
    if (base === "ng") {
      base = "nng";
    }
    if (base === "yü") {
      base = "yüü";
    }
    if (base.slice(-2) === "ng") {
      rev = -2;
    }
    if (
      base.length > 1 &&
      !vocals.includes(base.slice(-2, -1)) &&
      vocals.includes(base.slice(-1))
    ) {
      base += base.slice(-1);
    }
  }

  switch (Number(tone)) {
    case 1:
      return (
        <span key={index}>
          <span className="tUp">{base}</span>{" "}
        </span>
      );
    case 2:
      return (
        <span key={index}>
          <span className="tDown">{base.slice(0, rev)}</span>
          {finals[base.slice(rev)] + " "}
        </span>
      );
    case 4:
      return (
        <span key={index}>
          {base.slice(0, rev)}
          <span className="tDown">{base.slice(rev)}</span>{" "}
        </span>
      );
    case 5:
      return (
        <span key={index}>
          {base.slice(0, rev) + finals[base.slice(rev)] + " "}
        </span>
      );
    case 6:
      return (
        <span key={index}>
          <span className="tDown">{base}</span>{" "}
        </span>
      );
    default:
      return base + " ";
  }
};

export const convertYale = (base, tone, _type, _color) => {
  var _tTone = tone;
  if (_tTone === "5") {
    _tTone = "2";
  }

  base = base.split("");

  if (Number(tone) >= 3) {
    for (var vv2 = base.length - 1; vv2 > -1; vv2--) {
      if (["a", "e", "i", "o", "u"].includes(base[vv2])) {
        base.splice(vv2 + 1, 0, "h");
        break;
      }
    }
  }

  if (["1", "2", "4"].includes(_tTone)) {
    for (vv2 = 0; vv2 < base.length; vv2++) {
      if (["a", "e", "i", "o", "u"].includes(base[vv2])) {
        base[vv2] = yaleVocals[_tTone][base[vv2]];
        break;
      }
    }
  }

  return base.join("");
};
