import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { callService } from "../service/service";
import LinearProgress from "@mui/material/LinearProgress";
import "../App.css";

export default function Phrases({ changeStep, course }) {
  const [words, setWords] = useState([]);

  useEffect(() => {
    console.log(course);
    const checkWords = async () => {
      let resp = await callService("phrases", {
        token: localStorage.getItem("token"),
        course: course.id,
      });
      console.log(resp);
      if (resp && resp.status) {
      } else {
        window.location.reload(false);
      }
    };
    checkWords();
  }, []);

  const goBack = () => {
    changeStep("menu");
  };

  return (
    <div className="mainBox">
      <Button
        variant="contained"
        style={{ position: "fixed", top: 20 }}
        onClick={goBack}
      >
        Volver
      </Button>
      <div className="subBox">
        {words.length > 0 ? (
          words.map((o, index) => (
            <Button
              key={index}
              variant="contained"
              style={{ margin: 10 }}
              onClick={o.func}
            >
              {o.label}
            </Button>
          ))
        ) : (
          <div>
            <LinearProgress style={{ marginBottom: 20, marginTop: 20 }} />
          </div>
        )}
      </div>
    </div>
  );
}
