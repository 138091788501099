const recognition = new (window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition)();
recognition.lang = "yue-HK";
recognition.continuous = false;

var utterance;

export const recordVoice = (callBack, stopCallBack) => {
  /*if (valor === "") {
    var output = document.getElementById("datosdic"); // salida
    var action = document.getElementById("grabarvoz"); //botón
  } else {
    var action = document.getElementById("botongrabar"); //botón
  }*/

  /*recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition ||
    window.mozSpeechRecognition ||
    window.msSpeechRecognition)();
  recognition.lang = "yue-HK";*/

  // This runs when the speech recognition service starts
  /*recognition.onstart = function () {
    /*if (valor == "") {
      if (action.innerHTML == "🎤 Dictado") {
        action.innerHTML = "🎤 Grabando...";
      } else {
        action.innerHTML = "🎤 Dictado";
        recognition.stop();
      }
    } else {
      if (action.innerHTML == "Otra vez") {
        action.innerHTML = "🎤 Grabando";
        action.style.width = "160px";
      } else {
        action.innerHTML = "Otra vez";
        action.style.width = "100px";
        recognition.stop();
      }
    }
  };*/
  recognition.onspeechend = function () {
    /*if (valor === "") {
        action.innerHTML = "🎤 Dictado";
      } else {
        action.innerHTML = "Otra vez";
        action.style.width = "100px";
      }*/
    stopCallBack();
    recognition.stop();
  };

  // This runs when the speech recognition service returns result
  recognition.onresult = function (event) {
    var transcript = event.results[0][0].transcript;
    var confidence = event.results[0][0].confidence;
    //console.log(transcript);
    //console.log(confidence);
    callBack(transcript);
    /*if (valor == "") {
        if (alfabeto == 1) {
          cambiartextarea(transcript, "datosdic", 1);
        } else {
          output.value += transcript;
        }
      } else {
        muestrapuntos(
          transcript,
          valor,
          snd2,
          paso,
          xsesp,
          xyale,
          "",
          "",
          totales
        );
      }*/
  };
  recognition.start(); // comenzar grabación
};

export const stopRecording = () => {
  console.log("stop recording");
  recognition.stop();
};

export const listenText = (txt, callBack, vel = 0.7) => {
  //speechSynthesis.stop()
  if (recognition) {
    recognition.abort();
  }
  utterance = new SpeechSynthesisUtterance(txt);
  //utterance.text = txt
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    utterance.lang = "yue-HK";
  } else {
    utterance.lang = "zh-HK";
  }

  //utterance.voice = voices[0]
  utterance.volume = 1;
  utterance.rate = vel;
  setTimeout(() => {
    speechSynthesis.speak(utterance);
  }, 500);

  console.log(utterance);
  utterance.onend = () => {
    callBack();
  };
};

export const stopPlaying = () => {
  speechSynthesis.cancel();
};
